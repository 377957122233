.video-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  border-radius: 3.75rem 3.75rem 0 0;
  margin-top: -3.75rem;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-play-video {
  width: 10rem;
  height: 7.5rem;
  border-radius: 12.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border: none;
  z-index: 1;
}
