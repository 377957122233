@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
}
.card-item {
  display: flex;
  flex-direction: column;
  width: 19.37rem;
  height: 16.25rem;
  border-radius: 1.25rem;
  overflow: hidden;
  background-color: #1c1c1c;
  transition: all 0.4s ease;
  animation: pop 3s infinite;
}
.card-item:hover {
  animation: none;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6);
}
.card-item__wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}
.card-item__number {
  color: rgb(209, 218, 221);
  font-family: var(--font-family);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.88rem;
  letter-spacing: 10%;
  position: absolute;
  top: 1.875rem;
  left: 1.25rem;
}
.card-item__title {
  max-width: 12.5rem;
  color: rgb(255, 255, 255);
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 600;
  position: absolute;
  bottom: 1.875rem;
  left: 1.25rem;
}
