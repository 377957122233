/* Основні стилі слайдера */
.review-customers {
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.review-card {
  border: 0.06rem solid rgb(81, 91, 106);
  padding: 1.875rem 2.5rem;
  border-radius: 2.5rem;
  margin-bottom: 1.25rem;
  display: inline-block;
}

.review-card__img {
  width: 3.75rem;
  height: 3.75rem;
}

.review-card__top {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
}

.review-card__info {
  margin-left: 1.25rem;
}

.review-card__name {
  color: white;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.375rem;
}

.category__line {
  display: flex;
  align-items: center;
}

.review-card__category {
  font-family: var(--font-family);
  color: rgb(124, 107, 254);
  font-size: 0.88rem;
  font-weight: 600;
}

.review-card__arrows {
  width: 0.65rem;
  height: 0.65rem;
  margin-left: 0.533rem;
}

.review-card__description {
  color: white;
  font-family: var(--font-family);
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.5rem;
}
@media (max-width: 100rem) {
  .reviews__pagination {
    display: flex;
    justify-content: center;
    width: 9.375rem;
    height: 0.63rem;
    margin-top: 20px;
    position: absolute;
    bottom: 10rem;
    left: 50%;
    transform: translate(-50%);
  }

  .pagination-dot {
    width: 1.25rem;
    height: 0.63rem;
    margin: 0 5px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.63rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .pagination-dot.active {
    background-color: #fff;
  }
}

@media (max-width: 30rem) {
  .review-card {
    width: 19.38rem;
    padding: 1.25rem;
  }
  .review-card__description {
    line-height: 1.25rem;
  }
}
