.dropdown-menu {
  position: absolute;
  top: 3.75rem;
  left: -12.813rem;
  width: 33.13rem;
  height: 12.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.313rem;
  border-radius: 1.88rem;
  backdrop-filter: blur(1.25rem);
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in-out;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}
.menu-hover__touch {
  width: 11.88rem;
  height: 11.88rem;
  background: url('../../assets/image/droppdown/background.png') no-repeat
    center;
  border-radius: 1.56rem;
  display: flex;
  flex-direction: column;
  padding: 3.625rem 1.25rem 1.25rem 1.25rem;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
.menu-hover__touch:hover {
  transform: scale(1.05);
}
.menu-hover__touch p {
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.63rem;
  color: white;
}
.btn__getintouch {
  margin-top: 1.25rem;
  width: 6.63rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  background: rgb(255, 255, 255);
  font-family: var(--font-family);
  font-size: 0.88rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 300ms ease;
}
.btn__getintouch:hover {
  color: var(--color-purpure);
  transform: scale(1.1);
}
.menu-hover__left {
  width: 20rem;
  height: 11.88rem;
  border: 2px solid white;
  border-radius: 1.56rem;
  overflow: hidden;
  border-color: rgba(255, 255, 255, 0.8);
  transition: border-color 0.3s ease, transform 0.3s ease;
  z-index: 100;
}
.menu-hover__left:hover {
  border-color: rgba(255, 255, 255, 1);
  transform: scale(1.02);
}
.domes {
  display: flex;
  flex-wrap: wrap;
  background: white;
  gap: 2px;
  border-radius: 1.56rem;
}
.domes__item {
  width: 9.8rem;
  height: 5.75rem;
  position: relative;
  padding: 2rem 1rem 1.25rem 1.25rem;
  background: rgb(209, 218, 221);
  transition: all 400ms ease;
  overflow: hidden;
}
.domes__item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
  transition: all 0.3s ease;
}
.domes__item:hover::after {
  background: rgba(185, 195, 199, 0.5);
  transform: translateY(-5px);
}
.domes__link {
  position: relative;
  z-index: 2;
  font-family: var(--font-family);
  color: rgb(30, 30, 30);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration: none;
  transition: color 0.3s ease;
}
.domes__item:hover .domes__link {
  color: var(--color-purpure);
}
.domes__arrow {
  visibility: hidden;
  position: absolute;
  top: 1.063rem;
  right: 1.063rem;
}
.domes__item:hover .domes__arrow {
  visibility: visible;
}
.domes__item:hover {
  transform: translateY(-3px);
}
