.social-row__left p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 100%;
  color: #fff;
  margin-top: 10px;
}

.hero__social {
  display: flex;
  align-items: center;
}

.item__social {
  width: 3rem;
  height: 3rem;
  background: var(--color-text);
  border-radius: 50%;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.link__social {
  display: flex;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.hero-icons {
  width: 100%;
  height: 100%;
}
