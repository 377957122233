.mobile-menu {
  display: none;
}
.mobile-social-item {
  display: block;
  border: 0.06rem solid rgb(209, 218, 221);
}
.mobile-menu-language {
  display: none;
}
.mobile-btn-contact {
  display: none;
}
.mobile__bottom {
  display: block;
  margin-top: 2.813rem;
  display: flex;
  align-items: center;
  justify-content: start;
}
@media (max-width: 100rem) {
  .mobile-menu {
    position: fixed;
    top: 1.25rem;
    right: 1.875rem;
    bottom: 1.875rem;
    width: 36.25rem;
    height: 100%;
    background: rgb(30, 30, 30);
    color: white;
    z-index: 100;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease-in-out;
    padding: 8.75rem 3.75rem 3.125rem 3.75rem;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .mobile-menu::-webkit-scrollbar {
    display: none;
  }
  .mobile-menu__item {
    margin-bottom: 2.5rem;
  }
  .mobile-menu__item:last-child {
    margin-bottom: 12.75rem;
  }
  .mobile-menu__link:hover {
    color: rgb(124, 107, 254);
  }
  .mobile-menu__title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
  }
  .mobile-menu__link {
    color: rgb(255, 255, 255);
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25rem;
    transition: all 400ms ease;
  }
}
@media (max-width: 48rem) {
  .mobile-menu {
    top: 0.625rem;
    bottom: 0.625rem;
    right: 0.313rem;
    left: 0.313rem;
    width: 97%;
    height: 100%;
    color: white;
    z-index: 100;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    padding: 8.75rem 1.25rem 3.125rem 1.25rem;
  }
  .mobile-language {
    display: inline-flex !important;
  }
  .mobile-menu__item:last-child {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 35.5rem) {
  .mobile-menu-language {
    display: inline-block;
  }
  .mobile-btn-contact {
    display: block;
  }
  .mobile-btn {
    border-radius: 1.88rem !important;
    padding: 1.063rem 4.25rem !important;
  }
  .mobile__bottom {
    display: block;
    margin-top: 2.813rem;
    display: flex;
    align-items: center;
    justify-content: start;
  }
}
