.shira-product__cart {
  width: 40rem;
  height: 50rem;
  border-radius: 2.5rem;
  background: rgb(255, 255, 255);
  overflow: hidden;
  margin-bottom: 2.5rem;
  position: relative;
}
.shira-product__image {
  margin-top: 0.625rem;
  margin-left: 0.625rem;
  width: 38.75rem;
  height: 25rem;
  border-radius: 2.5rem;
  overflow: hidden;
}
.shira-product__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.products-cart:last-child {
  margin-bottom: 0rem;
}
.shira-product__content {
  padding: 3.75rem 2.5rem;
}
.shira-product__subtitle {
  padding-top: 0 !important;
  padding-bottom: 1.875rem;
}
.shira-product__description {
  max-width: 30.63rem;
}
.shira-product__btn {
  margin-top: 2.5rem;
  background: rgb(0, 0, 0) !important;
  position: absolute;
  bottom: 3.75rem;
  left: 2.5rem;
}
@media (max-width: 100rem) {
  .shira-product__cart {
    width: 30rem;
    height: 42rem;
    margin-bottom: 1.25rem;
  }
  .shira-product__image {
    width: 28.75rem;
    height: 20rem;
    border-radius: 2.5rem;
  }
  .shira-product__content {
    padding: 2.5rem 1.25rem 1.875rem 1.25rem;
  }
  .shira-product__description {
    max-width: 28rem;
  }
  .shira-product__btn {
    bottom: 1.875rem;
    left: 1.25rem;
  }
}
@media (max-width: 64rem) {
  .shira-product__cart {
    width: 20.63rem;
    height: 42.5rem;
  }
  .shira-product__image {
    width: 19.38rem;
    height: 20rem;
  }

  .shira-product__description {
    max-width: 18.75rem;
  }
}
