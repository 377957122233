.table-section {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 37.5rem;
  background-color: white;
  transition: bottom 0.4s ease;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  padding-top: 30px;
  padding-bottom: 4rem;
  border-radius: 3.75rem 3.75rem 0rem 0rem;
  z-index: 999;
}

.table-section.show {
  bottom: 0;
}

.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 0.06rem solid rgb(188, 196, 199);
  border-radius: 1.25rem;
  overflow: hidden;
}
.box-table {
  width: 100%;
}
.box-table td,
.box-table th {
  padding: 30px;
  border: none;
  text-align: center;
}

.box-table tr td {
  border-bottom: 0.04rem solid rgb(188, 196, 199);
  border-right: 0.04rem solid rgb(188, 196, 199);
}

.box-table tr td:last-child {
  border-right: none;
}
.table-row-parameters:last-child td {
  border-bottom: none;
}

.table-section__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.title-row {
  font-size: 1.13rem;
  font-weight: 600;
  line-height: 1.25rem;
}
.table-row-parameters td {
  font-size: 1.25rem;
  font-weight: 700;
}
@media (max-width: 100rem) {
  .table__container {
    width: 90%;
  }
  .table-section {
    height: 50%;
    overflow-y: scroll;
  }
}
@media (max-width: 75rem) {
  .box-table td,
  .box-table th {
    padding: 15px;
  }
}
@media (max-width: 62rem) {
  .box-table td,
  .box-table th {
    padding: 8px;
  }
  .table-section__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .close-btn {
    width: 3.75rem;
    height: 3.75rem;
  }
}
