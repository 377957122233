.mobile-planetarium-card {
  max-width: 16.25rem;
}
.mobile-planetarium-image {
  width: 2.94rem;
  height: 2.94rem;
  margin-bottom: 1.25rem;
}

.mobile-planetarium-text {
  color: rgb(255, 255, 255);
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}
