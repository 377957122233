.right-menu {
  display: flex;
  align-items: center;
  min-height: 50px;
  background: white;
  border-radius: 30px;
  cursor: pointer;
  z-index: 101;
}

.item-language {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.language-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  background: white;
  color: var(--color-text);
  cursor: pointer;
}

.btn-active {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #d1dadd;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.item-language :first-child {
  margin-left: 5px;
}
