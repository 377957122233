.universal-btn {
  border-radius: 2.5rem;
  background: rgb(124, 107, 254);
  color: rgb(255, 255, 255);
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 600;
  padding: 1.688rem 3.125rem;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  transition: all 400ms ease;
}
.universal-btn:hover {
  color: rgb(124, 107, 254);
  background: rgb(255, 255, 255);
}
