.projection {
  border-radius: 3.75rem;
  background: #d1dadd;
  margin-top: -6.2rem;
  transition: height 0.5s ease;
  position: relative;
}
.projection__wrapper {
  position: relative;
}
.projection__content {
  padding-top: 10rem;
  padding-bottom: 42.5rem;
  display: flex;
  gap: 1.25rem;
}
.projection__content.expanded {
  padding-bottom: 5.625rem;
}
.projection__title {
  flex: 1 1 49.2%;
  font-size: clamp(3.125rem, 2.1154rem + 4.4872vw, 7.5rem);
  font-weight: 700;
  color: rgb(0, 0, 0);
  max-width: 40rem;
}
.projection__text {
  padding-top: 2.5rem;
  flex: 1 1 49.2%;
  font-size: clamp(1.875rem, 1.7308rem + 0.641vw, 2.5rem);
  font-weight: 600;
  line-height: clamp(2.5rem, 2.2115rem + 1.2821vw, 3.75rem);
  max-width: 40rem;
}
.projection__video {
  bottom: -3.125rem;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease;
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  overflow: hidden;
}
.projection__video.relative {
  position: relative;
  top: 0;
  width: 100%;
  height: 45rem;
  border-radius: 5rem;
  overflow: hidden;
}
.projection__video.absolute {
  position: absolute;
}
.projection__video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
}
.btn-play {
  width: 10rem;
  height: 7.5rem;
  border-radius: 12.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border: none;
  z-index: 1;
}
.projection__description {
  padding-top: 5.625rem;
  padding-bottom: 10rem;
  max-width: 40rem;
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.2916rem;
  color: #333;
  margin: 0 auto;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  transform: translateY(0);
  position: relative;
}
.projection__description.expanded {
  transform: translateY(0);
}
.immersive-projection.expanded .projection__description {
  transform: translateY(0);
}
.play-icon {
  width: 0.79rem;
  height: 0.79rem;
  margin-left: 0.5rem;
}

.projection__description.closing {
  opacity: 0;
  transform: translateY(30px);
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.text-white {
  color: white !important;
}
@media (max-width: 100rem) {
  .projection__container {
    width: 90%;
  }
}
@media (max-width: 75rem) {
  .projection__container {
    width: 85%;
  }
  .projection__text {
    padding-top: 1rem;
  }
  .projection__video.relative {
    width: 100%;
    height: 35rem;
    border-radius: 5rem;
  }
  .projection__description {
    font-size: 1.25rem;
    line-height: 1.88rem;
    max-width: 34.375rem;
  }
  .stop-button {
    display: none;
  }
}
@media (max-width: 62rem) {
  .projection__wrapper {
    position: relative;
  }
  .projection__content {
    display: flex;
    flex-direction: column;
    gap: 0;
    overflow-y: hidden;
  }
  .projection__content.expanded {
    padding-bottom: 7.5rem;
    overflow-y: hidden;
  }
  .projection__video.relative {
    width: 100%;
    height: 30rem;
    border-radius: 5rem;
  }
  .projection__description {
    font-size: 1.25rem;
    line-height: 1.88rem;
    max-width: 28.125rem;
  }
  .projection__text {
    padding-top: 3.125rem;
  }
  .projection__content.expanded {
    padding-bottom: 7.5rem;
  }
}
@media (max-width: 48rem) {
  .projection {
    position: relative;
  }
  .projection__content {
    padding-bottom: 30rem;
  }
  .projection__video {
    width: 26.25rem;
    height: 26.25rem;
    border-radius: 50%;
  }
  .projection__video.relative {
    width: 100vw;
    height: 33.75rem;
    border-radius: 2.5rem;
  }
  .projection__description {
    font-size: 1.25rem;
    line-height: 1.88rem;
    max-width: 19.375rem;
  }
}
@media (max-width: 30rem) {
  .projection__container {
    width: 97%;
  }
  .projection {
    position: relative;
    overflow: hidden;
  }
  .projection__video.relative {
    width: 120%;
    height: 33.75rem;
    border-radius: 0rem;
    margin: 0;
  }
}
