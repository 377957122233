.banner-content {
  display: flex;
  white-space: nowrap;
}
.banner-email {
  margin: 0 2.5rem;
  font-family: var(--font-family);
  font-size: clamp(3.125rem, 2.5481rem + 2.5641vw, 5.625rem);
  font-weight: 600;
  color: transparent;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px solid black;
  transition: all 0.3s ease;
}
.banner-email:hover {
  color: black;
  -webkit-text-stroke: 1px black;
}
.banner-email-info {
  margin: 0 2.5rem;
}
.banner-education {
  margin-right: 2.5rem;
}
