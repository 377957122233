.conections__overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 53.75rem;
  background: rgba(8, 2, 11, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s ease, visibility 0.3s ease;
  -webkit-transition: opacity 0.4s ease, visibility 0.3s ease;
}

.conections__overlay.active {
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}

.conections__wrapper {
  width: 100%;
  height: 53.75rem;
  background-color: white;
  border-radius: 3.75rem 3.75rem 0 0;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  margin: 0;
  z-index: 3000000;
}

.conections__container {
  position: relative;
}

.conections__overlay.active .conections__wrapper {
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

.close-btn {
  background: rgb(209, 218, 221);
  border: none;
  padding: 1.464rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 4;
}
.close-btn-for-contecions {
  position: absolute;
  top: 2.5rem;
  left: 0.65;
}

.close-btn__icon {
  background: url(../../assets/image/geodesic-domes/btn-close.png) no-repeat
    center;
  width: 1.5rem;
  height: 1.5rem;
}

.conection__content {
  display: flex;
  justify-content: space-between;
  flex: 1 1 100%;
}

.con-left {
  flex: 1 1 50%;
}

.con-left__title {
  font-family: var(--font-family);
  font-size: clamp(3.75rem, 3.3173rem + 1.9231vw, 5.625rem);
  line-height: clamp(4.375rem, 3.7981rem + 2.5641vw, 6.875rem);
  font-weight: 600;
  max-width: 33.125rem;
  padding-top: 10rem;
}

.con-left__contacts {
  margin-top: 5.625rem;
}

.con-left__list {
  display: flex;
  flex-wrap: wrap;
}

.con-left__item {
  min-width: 19.375rem;
  margin-right: 1.25rem;
  margin-bottom: 1.875rem;
}

.con-left__text {
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.875rem;
  color: #000;
}

.con-left__data {
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.875rem;
  color: rgb(115, 136, 140);
}

.con-left__title span {
  color: rgb(124, 107, 254);
}

.con-right {
  padding-top: 3.75rem;
  max-width: 33.13rem;
  flex: 1 1 50%;
}

.con-right__name {
  font-size: clamp(1.88rem, 2.2778rem + -1.7679vw, 0.15625rem);
  margin-bottom: 2.5rem;
}

.con-right__input {
  margin-bottom: 1.25rem;
}

.con-right__input,
.con-right__textarea {
  width: 33.13rem;
  height: 3.75rem;
  border: 0.06rem solid rgb(115, 136, 140);
  border-radius: 1.88rem;
  box-sizing: border-box;
  padding: 1.25rem;
  font-size: 1rem;
}

.con-right__textarea {
  height: 9.38rem;
  resize: none;
}

.con-right__checkbox-wrapper {
  margin-top: 1.875rem;
  margin-bottom: 3.125rem;
  display: flex;
  align-items: center;
}

.con-right__checkbox-wrapper span {
  color: rgb(115, 136, 140);
  font-size: 1rem;
  font-weight: 600;
}

.con-right__checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid rgb(115, 136, 140);
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  transition: background 0.3s ease;
  -webkit-transition: background 0.3s ease;
  margin-right: 0.781rem;
}

.con-right__checkbox:checked {
  background: rgb(124, 107, 254);
}

.con-right__checkbox:checked::after {
  content: '✓';
  font-size: 12px;
  color: #fff;
  position: absolute;
  top: -2px;
  left: 2px;
}

.con-right__submit {
  width: 12.5rem;
  height: 3.75rem;
  border-radius: 1.88rem;
  background: rgb(124, 107, 254);
  color: #fff;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 3.75rem;
}

.con-right__submit:hover {
  background-color: #444;
}

textarea::placeholder,
input::placeholder {
  color: #73888c;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 600;
}

.con__error-message {
  color: #d93025;
  font-size: 14px;
  margin: -8px 10px 3px 10px;
  text-align: left;
}

@media (max-width: 100rem) {
  .conections__container {
    width: 85%;
  }
  .conections__wrapper {
    width: 100%;
    height: 60%;
    overflow-y: auto;
  }
  .con-left {
    flex: 0 0 40%;
  }
  .con-right {
    padding-top: 3.75rem;
    max-width: 33.13rem;
    flex: 0 0 40%;
  }
  .con-left__contacts {
    margin-top: 4rem;
  }
  .con-left__item {
    min-width: 8rem;
    margin-right: 2rem;
    margin-bottom: 1.875rem;
  }
}

@media (max-width: 75rem) {
  .conection__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1 1 100%;
  }
  .conections__wrapper {
    height: 86%;
  }
  .con-left__title {
    padding-top: 9.375rem;
    min-width: 26.875rem;
  }
  .con-left__contacts {
    max-width: 19.375rem;
    margin-top: 2rem;
  }
}

@media (max-width: 68.125rem) {
  .conection__content {
    display: block;
    position: relative;
    padding-bottom: 20.625rem;
  }
  .con-left__contacts {
    position: absolute;
    bottom: 0;
  }
  .con-right {
    padding-top: 1.875rem;
  }
}

@media (max-width: 48rem) {
  .con-left__title {
    min-width: 20.625rem;
  }
  .conections__container {
    width: 98.5%;
  }
  .con-right__input,
  .con-right__textarea {
    width: 100%;
  }
  .con-right__checkbox-wrapper {
    margin-top: 1.25rem;
    margin-bottom: 1.875rem;
  }
  .con-right__checkbox {
    width: 0.938rem;
    height: 0.938rem;
    margin-right: 1rem;
  }
}

@media (max-width: 30rem) {
  .con-right__checkbox-wrapper {
    display: block;
  }
  .conections__container {
    position: relative;
    padding-top: 2.5rem;
  }
}
