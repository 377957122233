.projects-card {
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  margin-bottom: 7.5rem;
}
.projects-card__year {
  color: rgb(36, 36, 36);
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}
.projects-card__subtitle {
  padding-top: 0 !important;
  margin-bottom: 1.25rem;
  font-weight: 600 !important;
  max-width: 38.1rem;
}
.card-description {
  font-weight: 600 !important;
  line-height: 1.25rem !important;
  margin-bottom: 1.875rem;
}
.projects-card__image {
  width: 40rem;
  height: 27.5rem;
  border-radius: 1.25rem;
}

@media (max-width: 48rem) {
  .projects-card {
    max-width: 20.63rem;
    margin-bottom: 3.125rem;
  }
  .projects-card__image {
    width: 20.63rem;
    height: 13.75rem;
    border-radius: 1.25rem;
  }
  .card-description {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 30rem) {
  /* Ваші стилі тут */
}
@media (max-width: 22.5rem) {
  /* Ваші стилі тут */
}
