.reviews {
  background: #242424;
  border-radius: 3.75rem;
  margin-top: -3.75rem;
  padding-bottom: 17.125rem;
  overflow: hidden;
  position: relative;
}
.reviews__title {
  margin: 0 auto;
}
.reviews__title {
  font-size: 5rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
  max-width: 40rem;
  text-align: center;
  padding-top: 8.125rem;
  padding-bottom: 5.625rem;
}
.reviews__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
}
.reviews::before {
  content: '';
  position: absolute;
  left: 10.625rem;
  top: 61.313rem;
  width: 39.19rem;
  height: 37.63rem;
  border-radius: 50%;
  filter: blur(25rem);
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(124, 107, 254),
    rgb(74, 64, 152) 58.5%,
    rgb(0, 56, 255) 100%
  );
  z-index: 1;
}
.reviews::after {
  content: '';
  position: absolute;
  right: 17.625rem;
  top: 61.313rem;
  width: 39.19rem;
  height: 37.63rem;
  border-radius: 50%;
  filter: blur(25rem);
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(0, 56, 255),
    rgb(255, 138, 0) 58.5%,
    rgb(0, 135, 154) 100%
  );
  z-index: 1;
}
@media (max-width: 100rem) {
  .reviews__container {
    width: 90%;
  }
  .reviews__wrapper {
    display: flex;
    overflow-x: scroll;
    gap: 1.25rem;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  .reviews__title {
    font-size: 3.13rem;
    line-height: 3.75rem;
    max-width: 20.63rem;
  }
  .reviews__group {
    flex: 0 0 80%;
    scroll-snap-align: center;
  }
  .reviews__wrapper::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 75rem) {
  .reviews__container {
    width: 85%;
  }
}

@media (max-width: 24rem) {
  .reviews__container {
    width: 97%;
  }
}
