/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

/*  css variables */
:root {
  --card-bg-01: #fdefe6;
  --card-bg-02: #d6e5fb;
  --card-bg-03: #ceebe9;
  --card-bg-04: #e2f2b2;
  --primary-color: #0a1d37;
  --hero-bg: #d6e5fb;
  --heading-fontSize: 2rem;
  --small-text-color: #999;
  --heading-text-color: #0a1d37;

  --font-family: 'Urbanist', sans-serif;
  --color-text: #1e1e1e;
  --color-purpure: #7c6bfe;
}

/* reset browser default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Urbanist', sans-serif;
  font-size: 16px;
  font-weight: 600;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

p {
  color: var(--small-text-color);
  font-size: 1rem;
}

ul {
  list-style: none;
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
}
img {
  width: 100%;
}
button {
  border: none;
}
input,
textarea,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
textarea:focus,
input:focus {
  border-color: #000;
}
.container {
  width: 82.5rem;
  margin: auto;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
@media (max-width: 48rem) {
  body.no-scroll {
    background-color: rgba(8, 2, 11, 0.6);
    height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}

.main-title__subtitle {
  padding-top: 3.75rem;
  font-family: var(--font-family);
  font-size: clamp(1.88rem, 1.7369rem + 0.6359vw, 2.5rem);
  font-weight: 700;
  line-height: clamp(2.5rem, 3.0318rem + -2.3635vw, 0.195625rem);
}

@media (max-width: 48rem) {
  .main-title__subtitle {
    padding-top: 2.5rem;
    font-size: clamp(1.88rem, 1.7369rem + 0.6359vw, 2.5rem);
    font-weight: 700;
  }
}
