.dome-swiper {
  position: relative;
  display: flex;
  margin-bottom: 5rem;
}
.description-container {
  margin-top: 7.5rem;
}
.swiper-row__title {
  font-weight: 600;
  min-width: 33.13rem;
  margin-right: 130px;
}
.swiper-row__description {
  max-width: 31.88rem;
  color: rgb(0, 0, 0);
  font-family: Urbanist;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-top: 1.875rem;
  margin-bottom: 2.5rem;
}
.swiper-row__btn {
  padding: 1.6rem 2.226rem;
}
.dome-image {
  width: 46.88rem;
  height: 43.75rem;
  border-radius: 1.25rem;
  position: relative;
  background: rgb(255, 255, 255);
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}
.dome-slider__img {
  width: 41.88rem;
  height: 29rem;
  object-fit: cover;
  margin: 7.313rem 2.5rem;
  transition: transform 0.5s ease-in-out;
}
.swiper-navigations {
  position: absolute;
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 30px;
  left: 40px;
}
.swiper-btn-pr,
.swiper-btn-next {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgb(247, 246, 244);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s;
}
.swiper-btn-pr:hover,
.swiper-btn-next:hover {
  background: rgb(124, 107, 254);
}
.swiper-btn-pr:hover path,
.swiper-btn-next:hover path {
  fill: white;
}
.swiper-btn-pr.disabled,
.swiper-btn-next.disabled {
  background: rgb(200, 200, 200);
  cursor: not-allowed;
}
.swiper-btn-pr.disabled path,
.swiper-btn-next.disabled path {
  fill: #999;
}
.arrow-left,
.arrow-right {
  width: 0.81rem !important;
  height: 0.79rem !important;
}
.arrow-left {
  transform: rotate(180deg);
}
.swiper-button-next:after,
.swiper-button-prev:after {
  content: '';
}
@media (max-width: 100rem) {
  .dome-swiper {
    width: 90%;
  }
  .dome-image {
    scale: 0.9;
  }
  .description-container {
    margin-top: 5rem;
  }
  .swiper-row__title {
    font-weight: 600;
    min-width: 23rem;
    margin-right: 30px;
  }
}
@media (max-width: 75rem) {
  .dome-image {
    scale: 0.6;
    transform: translateX(-10%);
  }
  .swiper-row__title {
    font-size: 1.88rem;
    font-weight: 600;
    line-height: 2.5rem;
  }
}
@media (max-width: 64rem) {
  .dome-image {
    transform: translateX(-20%);
  }
  .dome-swiper {
    margin-bottom: 0rem;
  }
  .dome-swiper:last-child {
    margin-bottom: 5rem;
  }
}
@media (max-width: 62rem) {
  .dome-image {
    scale: 1;
    transform: translate(25%, 40%);
    width: 20.63rem;
    height: 22.5rem;
  }
  .dome-slider__img {
    width: 19.38rem;
    height: 13.75rem;
    margin: 0.625rem 2.5rem 6.25rem 0.625rem;
  }
  .description-container {
    margin-top: 2.5rem;
    max-width: 40%;
  }
  .dome-swiper:last-child {
    margin-bottom: 10rem;
  }
}
@media (max-width: 48rem) {
  .dome-image {
    scale: 1;
    transform: translate(50%, 0%);
  }
  .dome-swiper {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 5rem;
  }
  .description-container {
    max-width: 90%;
  }
}
@media (max-width: 43.75rem) {
  .dome-image {
    transform: translate(0%, 0%);
  }
}
@media (max-width: 30rem) {
  .description-container {
    max-width: 20.63rem;
  }
  .dome-swiper {
    width: 97%;
  }
  .swiper-row__title {
    min-width: 20rem;
    margin-right: 0px;
  }
  .swiper-row__description {
    margin-top: 1.25rem;
  }
  .dome-swiper:last-child {
    margin-bottom: 13.125rem;
  }
}
