.main-title {
  padding-top: 13.125rem;
  font-family: var(--font-family);
  font-size: clamp(3.13rem, 2.6985rem + 1.9179vw, 5rem);
  font-weight: 700;
  line-height: clamp(3.75rem, 3.3162rem + 1.9282vw, 5.63rem);
}

@media (max-width: 48rem) {
  .main-title {
    padding-top: 8.75rem;
  }
}
@media (max-width: 30rem) {
  /* Ваші стилі тут */
}
