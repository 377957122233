.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  flex-wrap: wrap;
  overflow: hidden;
}

.tabs-component {
  margin-bottom: 200px;
}

.tab {
  width: 7.5rem;
  height: 5.63rem;
  border-radius: 3.13rem;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.88rem;
  cursor: pointer;
  transition: background 0.3s;
  margin-bottom: 10px;
}

.tab::before {
  content: '';
  background: url(../../assets//image/geodesic-domes/arrowfortabs.png) no-repeat
    center;
  background-size: contain;
  width: 0.56rem;
  height: 1.34rem;
  margin-right: 0.625rem;
}

.tab.active {
  background: rgb(30, 30, 30);
  color: white;
}

.compare-button {
  width: 8.13rem;
  height: 5.63rem;
  background: rgb(124, 107, 254);
  border-radius: 3.13rem;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 300ms;
}
.compare-button:hover {
  background: rgb(56, 55, 63);
}
.compare-button__icon {
  width: 1.56rem;
  height: 1.19rem;
}

.compare-button__text {
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 5px;
}

.content-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.static-image {
  width: 100%;
  height: 45.63rem;
  margin-bottom: 5rem;
}

.dynamic-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.tab-details__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  text-align: left;
}

.tab-details__subtitle {
  margin-top: 3.125rem;
  margin-bottom: 1.25rem;
  font-weight: 600;
  text-align: left;
}

.tab-details__list li {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2.5rem;
}

.tab-details__list-parameters li {
  line-height: 1.5rem !important;
  margin-bottom: 1.5rem;
}

.tab-details__column {
  min-width: 19.38rem;
}

.btn__view-projects {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: 600;
  padding: 32px 30px;
  border-radius: 2.5rem;
  background: rgb(0, 0, 0);
  cursor: pointer;
  transition: all 300ms;
}
.btn__view-projects:hover {
  color: rgb(255, 255, 255);
  background: rgb(101, 84, 84);
}
.dynamic-content__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.875rem;
}
@media (max-width: 100rem) {
  .tabs-component__container {
    width: 90%;
  }
  .static-image {
    scale: 0.8;
    margin-bottom: 3rem;
    object-fit: cover;
  }
  .tab {
    width: 5.5rem;
    height: 3.63rem;
    margin-bottom: 10px;
  }
  .tabs {
    display: flex;
    justify-content: center;
    gap: 1.25rem;
  }
  .tab-details__content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    text-align: left;
  }
}
@media (max-width: 77rem) {
  .static-image {
    margin-top: 2rem;
    scale: 0.85;
    width: 22.5rem;
    height: 18.75rem;
  }
  .btn__view-projects {
    position: absolute;
    width: 10rem;
    height: 5rem;
    bottom: -5rem;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .dynamic-content {
    padding-bottom: 4rem;
  }
}
@media (max-width: 75rem) {
  /* Ваші стилі тут */
}
@media (max-width: 48rem) {
  /* Ваші стилі тут */
}
@media (max-width: 30rem) {
  .geodesic-domes__container {
    width: 97%;
  }
  .tab-details__subtitle {
    margin-top: 2.5rem;
  }
}
@media (max-width: 22.5rem) {
  /* Ваші стилі тут */
}

@media (max-width: 1200px) {
  .tab-details__content {
    grid-template-columns: repeat(2, 1fr);
  }

  .tab {
    width: 6rem;
    height: 4.5rem;
  }

  .compare-button {
    width: 6.5rem;
    height: 4.5rem;
  }
}

@media (max-width: 768px) {
  .tab-details__content {
    grid-template-columns: 1fr;
  }

  .static-image {
    height: auto;
  }

  .tabs {
    justify-content: center;
  }

  .dynamic-content__top {
    flex-direction: column;
    align-items: flex-start;
  }

  .tab {
    width: 100%;
    height: 3.5rem;
    font-size: 1.2rem;
  }

  .compare-button {
    width: 100%;
    height: 3.5rem;
  }
}
