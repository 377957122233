.table-mobile-section {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: white;
  transition: bottom 0.4s ease;
  padding: 2rem;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  border-radius: 2rem 2rem 0 0;
  overflow-y: scroll;
}
.table-mobile-section__top {
  display: flex;
  flex-direction: column-reverse;

  margin-bottom: 1.5rem;
}
.table-mobile-section.show {
  bottom: 0;
}

.table-mobile__container {
  max-width: 100%;
}

.table-mobile-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.dome-cards-mobile {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.dome-card-mobile {
  width: 20.63rem;
  height: 11.25rem;
  border: 0.06rem solid rgb(188, 196, 199);
  border-radius: 1.25rem;
  padding: 1rem;
  background-color: #f8f9fa;
}
.table-mobile-title {
  max-width: 15.81rem;
}
.close-mobile-btn {
  margin-bottom: 1.875rem;
}

.dome-info-mobile {
  margin-bottom: 0.5rem;
}

.close-btn-mobile {
  background: none;
  border: none;
  cursor: pointer;
}

.close-btn-mobile span {
  font-size: 1.5rem;
  color: #333;
}

@media (max-width: 768px) {
  .dome-card-mobile {
    width: 100%;
    height: auto;
  }
}
