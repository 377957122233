.systems {
  background: url(../../assets/image/dome-models/bgForProjectionSystems.png),
    no-repeat center;
  background-size: cover;
  width: 100%;
  height: 81.25rem;
  margin-top: -3.125rem;
  overflow: hidden;
}
.systems__content {
  display: flex;
  justify-content: center;
  font-family: var(--font-family);
  padding-top: 18.75rem;
  padding-bottom: 13.75rem;
}
.content-box__left {
  flex: 1 1 50%;
}
.systems__title {
  font-size: clamp(3.125rem, 2.4038rem + 3.2051vw, 6.25rem);
  font-weight: 700;
  line-height: clamp(3.75rem, 2.8846rem + 3.8462vw, 7.5rem);
  color: rgb(255, 255, 255);
}
.btn__projections {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 1.9rem 3.5rem;
  border-radius: 2.5rem;
  background: rgb(255, 255, 255);
  position: relative;
  top: 23.75rem;
  left: 0;
  transition: all 600ms ease;
  cursor: pointer;
}
.btn__projections:hover {
  background: var(--color-purpure);
  color: white;
}
.content-box__right {
  flex: 1 1 50%;
}
.content-box__right-text {
  color: rgb(255, 255, 255);
  font-size: clamp(1.5rem, 1.3269rem + 0.7692vw, 2.25rem);
  font-weight: 600;
  line-height: clamp(2.25rem, 2.0469rem + 0.9026vw, 3.13rem);
}
@media (max-width: 100rem) {
  .systems__container {
    width: 90%;
  }
  .systems__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 43.75rem;
    padding-top: 11.25rem;
    padding-bottom: 7.5rem;
  }
  .systems__title {
    margin-bottom: 3.125rem;
  }
  .btn__projections {
    top: 32.4rem;
  }
}
@media (max-width: 75rem) {
  .systems__container {
    width: 85%;
  }
}

@media (max-width: 30rem) {
  .systems__container {
    width: 97%;
  }
  .systems__content {
    max-width: 19.688rem;
    padding-top: 11.25rem;
    padding-bottom: 7.5rem;
  }
  .btn__projections {
    top: 38rem;
    min-width: 310px;
  }
}
