.upcoming-event {
  background: url('../../assets/image/upcoming.png') no-repeat center;
  background-size: cover;
  width: 100%;
  border-radius: 3.75rem 3.75rem 0 0;
  overflow: hidden;
  position: relative;
  top: -3.75rem;
  padding-top: 8.75rem;
  padding-bottom: 13.75rem;
}

.upcoming-event__container {
  display: flex;
  justify-content: space-between;
  gap: 8.125rem;
}

.upcoming-event__content {
  flex: 1 1 33.13rem;
}

.upcoming-event__title,
.upcoming-event__subtitle,
.upcoming-event__text {
  color: rgb(255, 255, 255);
  font-family: Urbanist;
}

.upcoming-event__title {
  font-size: 3.75rem;
  font-weight: 600;
  margin-bottom: 3.13rem;
}

.upcoming-event__subtitle {
  max-width: 32rem;
  font-weight: 600;
  line-height: 3.5rem;
  margin-bottom: 2.5rem;
}

.upcoming-event__text {
  font-size: 1.88rem;
  font-weight: 500;
  line-height: 3.13rem;
}

.upcoming-event__form {
  display: flex;
  flex: 1 1 40rem;
  justify-content: center;
}

.form__best-offer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
}

.form-group {
  display: flex;
  gap: 1.25rem;
  position: relative;
}

.form-group:last-child {
  gap: 0;
}

.form-group--double {
  display: flex;
}

.form-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input--small {
  width: 19.38rem !important;
}

.input--small__areacode {
  width: 12.5rem !important;
}

.form-input__number {
  width: 26.25rem !important;
}

.form-input__lastname {
  width: 19.38rem !important;
}

.form-input {
  width: 40rem;
  height: 3.75rem;
  border: 0.06rem solid rgb(255, 255, 255);
  border-radius: 1.88rem;
  padding: 1rem;
  background: transparent;
  position: relative;
  color: white;
}

.form-input::placeholder,
.form-textarea::placeholder {
  color: rgb(255, 255, 255);
  font-family: Urbanist;
  font-size: 1rem;
  font-weight: 500;
}

.with-dropdown {
  padding-right: 2rem;
  cursor: pointer;
}

.dropdown-arrow {
  position: absolute;
  right: 1rem;
  width: 0.7rem;
  height: 0.41rem;
  background: white;
  border-radius: 0.1rem;
  clip-path: polygon(0 0%, 100% 0%, 50% 100%);
  transition: transform 0.3s ease;
}

.dropdown-arrow.active {
  transform: rotate(180deg);
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 1.88rem;
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 200px;
  overflow-y: hidden;
}

.dropdown-item {
  padding: 1rem;
  cursor: pointer;
  transition: background 0.2s;
}

.dropdown-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.form-textarea {
  width: 40rem;
  height: 9.38rem;
  border: 0.06rem solid rgb(255, 255, 255);
  border-radius: 1.88rem;
  padding: 1rem;
  background: transparent;
  resize: none;
}

.form-button {
  margin-top: 1.875rem;
  width: 40rem;
  height: 3.75rem;
  background: white;
  border: none;
  border-radius: 1.88rem;
  color: rgb(0, 0, 0);
  font-family: Urbanist;
  font-size: 1rem;
  font-weight: 600;
  transition: all 400ms ease;
  cursor: pointer;
}

.form-button:hover {
  background: rgb(138, 132, 132);
  color: white;
}

.input-error {
  border: 2px solid red;
}

@media (max-width: 100rem) {
  .upcoming-event__container {
    width: 90%;
    display: block;
    flex-direction: column;
    justify-content: center;
  }

  .upcoming-event__content {
    text-align: center;
    max-width: 40rem;
    margin: 0 auto;
  }

  .upcoming-event__subtitle {
    line-height: 2.5rem;
    margin: 0 auto;
  }

  .upcoming-event__title {
    margin-bottom: 1.875rem;
  }

  .upcoming-event__form {
    display: flex;
    flex: 1 1 40rem;
    justify-content: center;
  }

  .info-subtitle {
    font-size: 1.88rem;
  }

  .upcoming-event__text {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.88rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 48rem) {
  .upcoming-event {
    padding-top: 7.5rem;
    padding-bottom: 9.375rem;
  }

  .upcoming-event__form {
    flex-direction: column;
    align-items: stretch;
  }

  .input--small,
  .form-input__lastname {
    width: 100% !important;
  }

  .form-group {
    flex-direction: column;
    width: 100%;
  }

  .form-input {
    width: 100%;
  }

  .form-textarea {
    width: 100%;
  }

  .form-button {
    width: 100%;
    margin-top: 0.625rem;
  }
}

@media (max-width: 41.875rem) {
  .form-input__number {
    width: 21rem !important;
  }
}

@media (max-width: 32.625rem) {
  .form-input__number {
    width: 18rem !important;
  }
  .input--small__areacode {
    position: relative !important;
  }
  .dropdown-arrow-code {
    position: absolute;
    left: 48%;
  }
}
@media (max-width: 48rem) {
  .input--small__areacode {
    position: relative !important;
  }
  .dropdown-arrow-code {
    position: absolute;
    left: 25%;
  }
}
@media (max-width: 40.625rem) {
  .dropdown-arrow-code {
    left: 30%;
  }
}
@media (max-width: 25rem) {
  .dropdown-arrow-code {
    left: 50%;
  }
}
