/* -------------Planetarium-------------- */
.planetarium {
  background: rgb(247, 246, 244);
  padding-bottom: 18.125rem;
}

.planetarium__title {
  max-width: 29rem;
  padding-bottom: 5.625rem;
  background: linear-gradient(
    135deg,
    rgb(0, 56, 255),
    rgb(35, 102, 181),
    rgb(124, 107, 254)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
@media (max-width: 100rem) {
  .planetarium__container {
    width: 80%;
  }
}

@media (max-width: 62rem) {
  .planetarium__title {
    padding-bottom: 3.75rem;
  }
}
@media (max-width: 48rem) {
  .planetarium {
    padding-bottom: 16.25rem;
  }
}
@media (max-width: 30rem) {
  .planetarium__rows {
    width: 50%;
  }
  .planetarium__container {
    width: 97%;
  }
}
@media (max-width: 22.5rem) {
  /* Ваші стилі тут */
}
/* -----------Projection Dome System---------- */
.projection-system {
  background: rgb(229, 239, 255);
  position: relative;
  overflow: hidden;
}
.projection-system__wrapper {
  padding-bottom: 16.25rem;
}

.projection-system__title {
  max-width: 33.13rem;
  background: linear-gradient(
    132.92deg,
    rgb(124, 107, 254),
    rgb(0, 56, 255),
    rgb(255, 138, 0)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding-top: 18.125rem;
}
.projection-system__subtitle {
  padding-top: 2.5rem !important;
  max-width: 40rem;
}
.projection-system__text {
  padding-top: 2.5rem;
  max-width: 46.88rem;
  padding-bottom: 5.625rem;
}
.projection-system__product-title {
  color: rgb(124, 107, 254) !important;
}
.projection-system__product-description {
  padding-bottom: 0 !important;
}
.projection-system__product-subtitle {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.13rem;
}
.projection-system__product-btn {
  display: block;
}
.projection-system__product-btn-none {
  display: none;
}
.product-system__card {
  position: relative !important;
}
.projection-system__product-img {
  width: 38.75rem !important;
  height: 47.5rem !important;
  border-radius: 1.25rem !important;
  position: absolute;
  top: 1.25rem !important;
  right: 1.25rem !important;
}
.projection-system__product-img--small {
  width: 38.75rem;
  height: 45rem !important;
}
.projection-system__product-subtitle {
  max-width: 22.5rem !important;
  padding-top: 3.125rem !important;
}
.projection-system__product-description {
  min-height: 25.25rem;
  max-width: 25rem !important;
  font-size: 1rem !important;
}
.projection-system__product-description-small {
  min-height: 16.25rem;
  max-width: 27rem !important;
  font-size: 1rem !important;
  line-height: 2.33rem !important;
  margin-bottom: 6.25rem !important;
  padding-bottom: 0 !important;
}
.projection-system__product-content {
  padding-bottom: 5rem !important;
}
@media (max-width: 100rem) {
  .projection-system__container {
    width: 80%;
  }
  .projection-system__product-img,
  .projection-system__product-img--small {
    width: 19.38rem !important;
    height: 20rem !important;
    top: 50% !important;
    transform: translateY(-50%);
    right: 0.625rem;
  }
  .product-system__card {
    position: relative !important;
  }
}
@media (max-width: 75rem) {
  .projection-system__product-img,
  .projection-system__product-img--small {
    display: block !important;
    position: static !important;
    width: 19.38rem !important;
    height: 20rem !important;
    top: 0% !important;
    right: 0.625rem;
    transform: translate(0%, 0%) !important;
  }
  .product-system__card {
    position: relative !important;
  }
  .projection-system__title {
    padding-top: 13.75rem !important;
  }
}

@media (max-width: 48rem) {
  .projection-system__product-img,
  .projection-system__product-img--small {
    width: 19.38rem !important;
    height: 15rem !important;
  }
  .projection-system__product-subtitle {
    padding-top: 1.25rem !important;
    padding-bottom: 1.875rem !important;
  }
  .projection-system__product-description,
  .projection-system__product-description-small {
    max-width: 18.125rem !important;
  }
  .projection-system__product-description {
    min-height: 26.25rem !important;
    line-height: 1.88rem !important;
  }
  .projection-system__product-btn {
    display: none;
  }
  .projection-system__title {
    padding-top: 8.75rem !important;
  }

  .projection-system__product-content {
    padding-bottom: 1.875rem !important;
  }
  .projection-system__product-description-small {
    margin-bottom: 0rem !important;
    line-height: 1.65rem !important;
  }
  .projection-system__text {
    padding-top: 1.875rem;
    padding-bottom: 5rem;
  }
}
@media (max-width: 30rem) {
  .projection-system__product-btn {
    display: none !important;
  }

  .projection-system__container {
    width: 97%;
  }
}

/* -----------Geodesic Domes---------- */
.geodesic-domes__page {
  background: rgb(247, 246, 244);
  overflow: hidden;
}
.geodesic-domes__title {
  margin-bottom: 1.875rem;
  max-width: 40rem;
  background: linear-gradient(
    132.92deg,
    rgb(124, 107, 254),
    rgb(0, 56, 255),
    rgb(255, 138, 0)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.geodesic-domes__subtitle {
  padding-top: 0;
  max-width: 46.88rem;
}
.geodesic-domes__texts-block {
  display: flex;
  gap: 1.25rem;
  padding-top: 5rem;
  padding-bottom: 6.875rem;
}
.geodesic-domes__text-left {
  max-width: 40rem;
}
.geodesic-domes__text-right {
  max-width: 33.13rem;
}
@media (max-width: 100rem) {
  .geodesic-domes__container {
    width: 90%;
  }
}
@media (max-width: 75rem) {
  .geodesic-domes__texts-block {
    display: block;
    padding-top: 30px;
    padding-bottom: 60px;
  }
  .geodesic-domes__title {
    padding-top: 8.75rem !important;
    margin-bottom: 2.5rem;
  }
}

/* -----------Projection Dome Rental---------- */

.projection-dome-rental {
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  padding-bottom: 16.25rem;
  overflow: hidden;
}
.projection-dome-rental__container {
  position: relative;
}
.projection-dome-rental__title {
  max-width: 30rem;
  background: linear-gradient(
    135deg,
    rgb(0, 56, 255),
    rgb(35, 102, 181),
    rgb(124, 107, 254)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.ellipse1 {
  filter: blur(12.5rem);
  background: rgb(0, 56, 255);
  position: absolute;
  width: 80.57rem;
  height: 9.38rem;
  left: 43.75rem;
  top: 70.62rem;
  transform: rotate(-38.24deg);
  z-index: -1;
}
.ellipse2 {
  filter: blur(12.5rem);
  background: rgb(35, 102, 181);
  position: absolute;
  width: 80.57rem;
  height: 9.38rem;
  left: 34.94rem;
  top: 65.56rem;
  transform: rotate(-38.24deg);
  z-index: -1;
}
.ellipse3 {
  filter: blur(12.5rem);
  background: rgb(255, 138, 0);
  position: absolute;
  width: 80.57rem;
  height: 9.38rem;
  left: 29.19rem;
  top: 60.22rem;
  transform: rotate(-38.24deg);
  z-index: -1;
}
.ellipse4 {
  filter: blur(12.5rem);
  background: rgb(62, 211, 67);
  position: absolute;
  width: 59.13rem;
  height: 9.38rem;
  left: 23.88rem;
  top: 52.28rem;
  transform: rotate(-38.24deg);
  z-index: -1;
}
.ellipse5 {
  filter: blur(12.5rem);
  background: rgb(196, 188, 255);
  position: absolute;
  width: 59.13rem;
  height: 9.38rem;
  left: 18.94rem;
  top: 46.35rem;
  transform: rotate(-38.24deg);
  z-index: -1;
}
.ellipse6 {
  filter: blur(12.5rem);
  background: rgb(124, 107, 254);
  position: absolute;
  width: 59.13rem;
  height: 9.38rem;
  left: 18.44rem;
  top: 33.72rem;
  transform: rotate(-38.24deg);
  z-index: -1;
}
.ellipse7 {
  filter: blur(12.5rem);
  background: rgb(124, 107, 254);
  position: absolute;
  width: 59.13rem;
  height: 7.62rem;
  left: 60.69rem;
  top: 70.22rem;
  transform: rotate(-38.24deg);
  z-index: -1;
}
.ellipse8 {
  filter: blur(12.5rem);
  visibility: hidden;
  background: rgb(0, 56, 255);
  position: absolute;
  width: 59.13rem;
  height: 9.38rem;
  left: -7.5rem;
  top: 26.41rem;
  transform: rotate(-38.24deg);
  z-index: -1;
}
.projection-dome-rental__text {
  max-width: 40rem;
}
.projection-dome-rental__text-small {
  max-width: 33.13rem;
}
.projection-dome-rental__subtitle {
  padding-top: 1.875rem !important;
}
.projection-dome-rental__content {
  display: flex;
  justify-content: space-between;
  padding-top: 3.75rem;
}
.projection-dome-rental__images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.domes-rental__image-left {
  width: 26.25rem;
  height: 32.5rem;
  margin-top: 5.625rem;
  margin-right: 28.75rem;
}
.pr-dome-img {
  border-radius: 1.25rem;
}
.domes-rental__image-right {
  width: 26.25rem;
  height: 28.75rem;
  margin-top: 10rem;
  margin-bottom: 5.625rem;
  padding-right: 0;
}
.domes-rental__image-center {
  width: 33.13rem;
  height: 23.75rem;
  align-items: center;
  margin: 0 auto;
}
.projection-dome-rental__details {
  max-width: 51.25rem;
  margin: 7.5rem auto;
}
.pr-dome__subtitle,
.pr-dome__text span {
  color: rgb(30, 30, 30);
}
.pr-dome__subtitle {
  max-width: 33.06rem;
  padding-top: 2.5rem !important;
  padding-bottom: 1.875rem;
}
.pr-dome-images {
  position: relative;
}
.pr-dome-image__left {
  width: 33.13rem;
  height: 23.75rem;
  margin-right: 14.4rem;
}
.pr-dome-image__right {
  margin-top: 3.75rem;
  width: 33.13rem;
  height: 23.75rem;
  margin-bottom: 3.75rem;
}
.pr-dome-image__center {
  width: 26.25rem;
  height: 32.5rem;
}
@media (max-width: 100rem) {
  .projection-dome-rental__container {
    width: 90%;
  }
  .projection-dome-rental__content {
    display: block;
  }
  .domes-rental__image-left {
    margin-right: 10rem;
  }
  .pr-dome-image__left {
    margin-right: 3rem;
  }
  .pr-dome-img {
    scale: 0.8;
    object-fit: cover;
  }
}
@media (max-width: 75rem) {
  .projection-dome-rental__images {
    display: block;
    transform: translateX(25%);
  }
  .pr-dome-img {
    scale: 1;
  }
  .domes-rental__image-right {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
}
@media (max-width: 62rem) {
  .projection-dome-rental__images {
    transform: translateX(15%);
  }
}
@media (max-width: 48rem) {
  .projection-dome-rental__images {
    transform: translateX(0%);
  }
  .projection-dome-rental__content {
    padding-top: 2.5rem;
  }
  .pr-dome-image__left {
    margin-right: 0;
    width: 20.63rem;
    height: 23.75rem;
  }
  .domes-rental__image-left {
    width: 20.63rem;
    height: 24.38rem;
    margin-top: 5.625rem;
    margin-right: 0rem;
  }
  .domes-rental__image-right {
    width: 20.63rem;
    height: 23.75rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .domes-rental__image-center,
  .pr-dome-image__center {
    width: 20.63rem;
    height: 18.75rem;
  }
  .pr-dome-image__right {
    margin-top: 1.25rem;
    width: 20.63rem;
    height: 22.5rem;
    margin-bottom: 1.25rem;
  }
  .projection-dome-rental__details {
    margin-top: 5.625rem;
    margin-bottom: 4.375rem;
  }
  .projection-dome-rental {
    padding-bottom: 14.375rem;
  }
}
@media (max-width: 30rem) {
  .projection-dome-rental__container {
    width: 97%;
  }
}

/* -------------Our Projects --------------- */
.our-projects {
  background: rgb(229, 239, 255);
  padding-bottom: 22.5rem;
}

.our-projects__title {
  background: linear-gradient(
    132.92deg,
    rgb(124, 107, 254),
    rgb(0, 56, 255),
    rgb(255, 138, 0)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 5.625rem;
  max-width: 30rem !important;
}

.our-projects__wrapper {
  display: flex;
  justify-content: space-between;
}

.our-projects__column:nth-child(2) {
  padding-top: 7.5rem;
}

.projects-card:last-child {
  margin-bottom: 0;
}
@media (max-width: 100rem) {
  .our-projects__container {
    width: 90%;
  }
  .our-projects__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.25rem;
  }
}

@media (max-width: 48rem) {
  .our-projects__wrapper {
    gap: 1.25rem;
  }
  .our-projects__title {
    margin-bottom: 3.75rem;
    max-width: 20.63rem;
  }
  .our-projects__column:nth-child(2) {
    padding-top: 3.125rem;
  }
  .our-projects {
    padding-bottom: 13.75rem;
  }
}

/* -------------Shira Softwares --------------- */
.shira-softwares {
  background: rgb(247, 246, 244);
}
.shira-softwares__title {
  max-width: 40rem;
  background: linear-gradient(
    135deg,
    rgb(0, 56, 255),
    rgb(35, 102, 181),
    rgb(124, 107, 254)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 6.25rem;
}
.shira-softwares__container {
  overflow: hidden;
}
.shira-softwares__wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16.25rem;
}
.shira-softwares__column--right {
  padding-top: 3.125rem;
}
@media (max-width: 100rem) {
  .shira-softwares__container {
    width: 90%;
  }
  .shira-softwares__wrapper {
    justify-content: center;
    gap: 5rem;
    padding-bottom: 16.25rem;
  }
  .shira-softwares__column--right {
    padding-top: 0rem;
  }
}
@media (max-width: 75rem) {
  .shira-softwares__container {
    width: 96%;
  }
  .shira-softwares__wrapper {
    gap: 1.25rem;
  }
}
@media (max-width: 64rem) {
  .shira-softwares__wrapper {
    gap: 3rem;
  }
}
@media (max-width: 62rem) {
}

@media (max-width: 48rem) {
  .shira-softwares__container {
    width: 97%;
  }
  .shira-softwares__title {
    max-width: 18rem;
    margin-bottom: 3.75rem;
  }
  .shira-softwares__wrapper {
    display: block;
    padding-bottom: 16.25rem;
  }
}

/* -------------Sota Domes --------------- */
.sota-dome__page {
  background: rgb(229, 239, 255) no-repeat center;
  overflow-x: hidden;
}
.sota-dome__bg {
  background: url('../src/assets/image/sota-dome/bg-image-sota.jpeg') no-repeat
    center;
  background-size: cover;
  width: 100%;
  position: relative;
}
.sota-dome__content {
  padding-top: 25rem;
  padding-bottom: 25rem;
  display: flex;
  justify-content: space-between;
}
.sota-dome__title {
  padding-top: 0 !important;
  color: rgb(255, 255, 255) !important;
  -webkit-text-fill-color: white;
  max-width: 29rem;
}
.sota-dome__subtitle {
  max-width: 40rem;
  padding-top: 0 !important;
  color: rgb(255, 255, 255);
}
.some-of-uses {
  position: relative;
  width: 100%;
  margin-top: -3.75rem;
  background: rgb(36, 36, 36);
  border-radius: 3.75rem;
  padding-bottom: 7.5rem;
}
.some-of-uses__title {
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-weight: 600 !important;
  padding-top: 7.5rem;
  padding-bottom: 3.75rem;
}
.some-of-uses__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
}
.sota-details {
  margin-top: 10rem;
  margin-bottom: 15.625rem;
}
.sota-details__container {
  display: flex;
  justify-content: space-between;
  gap: 8.125rem;
}
.sota-details__content {
  flex: 0 0 40rem;
  padding-bottom: 12.5rem;
}
.sota-details__subtitle {
  font-weight: 600 !important;
  line-height: 2.5rem !important;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem !important;
}
.sota-details__list {
  max-width: 36rem;
}
.sota-details__item {
  margin-bottom: 1.25rem;
}

.sota-details__characteristics {
  position: relative;
  padding-left: 1.375rem;
}
.sota-details__characteristics::before {
  content: '';
  width: 0.75rem;
  height: 0.63rem;
  border-radius: 0.63rem;
  background: rgb(124, 107, 254);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.mt-characteristics {
  color: rgb(0, 0, 0);
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.88rem;
}
.maintenance-details__subtitle,
.assembly-details__subtitle {
  line-height: 3.13rem !important;
  margin-bottom: 1.25rem;
}
.sota-details__maintenance,
.sota-details__assembly {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.13rem;
  max-width: 40rem;
}

.sota-details__images {
  flex: 1 1 40rem;
}
.sota-details__image {
  width: 40rem;
  height: 25rem;
  border-radius: 2.5rem;
  overflow: hidden;
  margin-bottom: 1.25rem;
}
.sota-details__image:last-child {
  margin-bottom: 0;
}
@media (max-width: 100rem) {
  .sota-dome__container {
    width: 90%;
  }
  .some-of-uses__container {
    width: 90%;
    justify-content: center;
  }
  .sota-details__container {
    width: 90%;
    display: flex;
  }
  .sota-dome__content {
    display: flex;
    justify-content: space-between;
  }
  .sota-dome__title {
    max-width: 22rem;
  }
  .sota-dome__subtitle {
    max-width: 30rem;
  }
  .sota-details {
    margin-top: 7.5rem;
  }
  .sota-details__content {
    flex: 1 1 30rem;
  }
  .sota-details__images {
    flex: 1 1 30rem;
  }
  .sota-details__subtitle {
    padding-bottom: 1.875rem;
    padding-top: 0 !important;
  }
  .sota-details__image {
    margin-bottom: 9rem;
  }
  .sota-details__image {
    width: 28rem;
    height: 17.5rem;
    border-radius: 2.5rem;
  }
}
@media (max-width: 75rem) {
  .sota-details__image {
    margin-bottom: 19rem;
  }
}
@media (max-width: 64rem) {
  .sota-details__container {
    width: 90%;
    display: block;
    position: relative;
  }
  .sota-details__image {
    display: none;
  }
  .sota-details__image:first-child {
    display: block;
    position: absolute;
    top: 37rem;
    left: 50%;
    transform: translate(-50%);
    margin-bottom: 0;
  }
  .assembly-details__subtitle {
    padding-top: 20rem;
  }
}
@media (max-width: 62rem) {
  .sota-dome__content {
    display: block;
    text-align: left;
  }
  .sota-dome__title {
    margin-bottom: 3.125rem;
  }
  .sota-dome__content {
    padding-top: 16.875rem;
    padding-bottom: 15.625rem;
  }
}
@media (max-width: 48rem) {
  .mt-characteristics {
    font-size: 1.25rem;
    line-height: 1.63rem;
  }
  .sota-details__image:first-child {
    top: 39rem;
    width: 20rem;
    height: 13.75rem;
    border-radius: 1.88rem;
  }
}
@media (max-width: 30rem) {
  .sota-dome__container {
    width: 97%;
  }
  .some-of-uses__title {
    max-width: 14rem !important;
    text-align: center;
    padding-bottom: 3.125rem;
  }
  .sota-dome__subtitle {
    max-width: 30rem;
    font-size: 1.63rem;
  }
  .sota-details__content {
    padding-bottom: 8.75rem;
  }
  .sota-details__image:first-child {
    top: 44rem;
  }
}
@media (max-width: 23.75rem) {
  .sota-details__image:first-child {
    top: 47rem;
  }
  .assembly-details__subtitle {
    padding-top: 17rem;
  }
}
/* ----------------------Air-Bubble-Domes------------------------- */

.air-bubble-domes {
  overflow: hidden;
}
.air-bubble-domes__title {
  max-width: 40rem;
  background: linear-gradient(
    135deg,
    rgb(0, 56, 255),
    rgb(35, 102, 181),
    rgb(124, 107, 254)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding-bottom: 5.625rem;
}
.air-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.air-content__right {
  max-width: 33.13rem;
}
.air-content__subtitle {
  color: rgb(30, 30, 30);
  font-family: Urbanist;
  font-size: clamp(1.88rem, 1.5915rem + 1.2821vw, 3.13rem);
  font-weight: 600;
  line-height: clamp(2.5rem, 2.0662rem + 1.9282vw, 4.38rem);
  padding-bottom: 1.875rem;
}
.air-content__bottom {
  margin-top: -9rem;
  min-width: 30rem;
}
.air-content__textforbutton {
  padding-top: 4.375rem;
  padding-bottom: 1.875rem;
  max-width: 13.75rem;
  color: rgb(0, 0, 0);
  font-family: Urbanist;
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 1.88rem;
}
.air-bubble-domes__content {
  padding-bottom: 7.5rem;
}
.air-content__left {
  color: rgb(0, 0, 0);
  font-family: Urbanist;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.13rem;
  max-width: 40rem;
}
.air-bubble-domes__images {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  padding-bottom: 15.625rem;
}
.air-bubble-domes__img {
  border-radius: 1.88rem;
}
.air-bubble-domes__img--small {
  width: 33.13rem;
  height: 23.75rem;
}
.air-bubble-domes__img--medium {
  width: 46.88rem;
  height: 23.75rem;
}
.air-bubble-domes__img--large {
  width: 53.75rem;
  height: 35rem;
}
.air-bubble-domes__img--extra-medium {
  width: 26.25rem;
  height: 35rem;
}
.air-bubble-domes__img--big {
  width: 81.25rem;
  height: 30rem;
}
@media (max-width: 100rem) {
  .air-bubble-domes__container {
    width: 90%;
  }
  .air-bubble-domes__images {
    justify-content: center;
    gap: 0;
  }
  .air-bubble-domes__img--small {
    scale: 0.8;
  }
  .air-bubble-domes__img--medium {
    scale: 0.8;
  }
  .air-bubble-domes__img--large {
    scale: 0.8;
  }
  .air-bubble-domes__img--extra-medium {
    scale: 0.8;
  }
  .air-bubble-domes__img--big {
    scale: 0.8;
  }
  .air-bubble-domes__img {
    object-fit: cover;
  }
  .air-bubble-domes__content {
    padding-bottom: 5.625rem;
  }
  .air-bubble-domes__title {
    padding-bottom: 2.5rem;
  }
  .air-content__textforbutton {
    padding-top: 3.75rem;
    padding-bottom: 1.375rem;
  }
  .air-content__right {
    max-width: 25rem;
  }
  .air-content__left {
    max-width: 35rem;
  }
}
@media (max-width: 75rem) {
  .air-content__left {
    max-width: 28rem;
  }
  .air-content__right {
    max-width: 24rem;
  }
  .air-content__left {
    font-size: 1.25rem;
    line-height: 1.88rem;
  }
}
@media (max-width: 55rem) {
  .air-content__bottom {
    margin-top: 0rem;
    min-width: 30rem;
  }
}
@media (max-width: 62rem) {
  .air-content__right {
    max-width: 20rem;
  }
}
@media (max-width: 48rem) {
  .air-content {
    display: block;
  }
  .air-bubble-domes__content {
    padding-bottom: 5.625rem;
  }

  .air-bubble-domes__img {
    object-fit: cover;
    scale: 1;
  }
  .air-bubble-domes__img--small {
    width: 20.63rem;
    height: 14.38rem;
  }
  .air-bubble-domes__img--medium {
    width: 20.63rem;
    height: 11.25rem;
  }
  .air-bubble-domes__img--large {
    width: 20.63rem;
    height: 16.25rem;
  }
  .air-bubble-domes__img--extra-medium {
    width: 20.63rem;
    height: 27.5rem;
  }
  .air-bubble-domes__img--big {
    width: 20.63rem;
    height: 10rem;
  }
  .air-content__subtitle {
    padding-bottom: 1.875rem;
    font-weight: 700;
  }
}
@media (max-width: 30.625rem) {
  .air-content__textforbutton {
    bottom: -14.125rem;
  }
  .air-content__btn {
    bottom: -18.5rem;
  }
}
@media (max-width: 24rem) {
  .air-bubble-domes__container {
    width: 97%;
  }
  .air-content__textforbutton {
    bottom: -10.125rem;
  }
  .air-content__btn {
    bottom: -14.5rem;
  }
}
@media (max-width: 22rem) {
  .air-content__textforbutton {
    bottom: -14.125rem;
  }
  .air-content__btn {
    bottom: -18.5rem;
  }
}

/* -------------------Inflatable-Dome------------ */
.inflatable-dome__page {
  background: rgb(247, 246, 244);
  overflow: hidden;
}
.inflatable-dome {
  background: url('../src/assets/image/inflatable-planetarium/inflatable-dome-img.jpg')
    no-repeat center;
  background-size: cover;
  width: 100%;
  position: relative;
}
.mobile-planetarium-features {
  width: 100%;
  border-radius: 3.75rem;
  background: rgb(36, 36, 36);
  position: relative;
  margin-top: -3.75rem;
  z-index: 2;
}
.mobile-planetarium-features__title {
  color: rgb(255, 255, 255);
  padding-top: 7.5rem;
  padding-bottom: 5.625rem;
  text-align: center;
  max-width: 26.25rem;
  margin: 0 auto;
}
.mobile-planetarium-features__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4.375rem;
  padding-bottom: 9.875rem;
}
.inflatable-swipers {
  margin-top: 10rem;
}
.inflatable-dome__video {
  background: url(./assets/image/projection-system/section-img.jpg) no-repeat
    center;
  width: 100%;
  height: 1200px;
  background-size: cover;

  position: relative;
  margin-top: -3.75rem;
  z-index: 1;
}
@media (max-width: 100rem) {
  .mobile-planetarium-features__container {
    width: 90%;
  }
  .mobile-planetarium-features__wrapper {
    justify-content: center;

    padding-bottom: 9.875rem;
  }
}
@media (max-width: 75rem) {
  .inflatable-swipers {
    margin-top: 6.875rem;
  }
}
@media (max-width: 62rem) {
  /* Ваші стилі тут */
}
@media (max-width: 48rem) {
  .mobile-planetarium-features__title {
    padding-bottom: 3.125rem;
  }
}
@media (max-width: 30rem) {
  .mobile-planetarium-features__wrapper {
    gap: 2.5rem;
  }
  .mobile-planetarium-features__container {
    width: 97%;
  }
  .mobile-planetarium-features__title {
    max-width: 16rem !important;
    font-weight: 600 !important;
  }
}
@media (max-width: 22.5rem) {
  /* Ваші стилі тут */
}
/* -------------Contacts----------------- */
.contacts {
  background: rgb(229, 239, 255);
  position: relative;
}
.contacts__title {
  background: linear-gradient(
    135deg,
    rgb(0, 56, 255),
    rgb(35, 102, 181),
    rgb(124, 107, 254)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.contacts-text {
  max-width: 46.88rem;
  padding-top: 2.5rem;
}
.ds-text {
  color: rgb(30, 30, 30);
  font-family: var(--font-family);
  font-size: 1.88rem;
  font-weight: 500;
  line-height: 2.5rem;
}

@media (max-width: 75rem) {
  .ds-text {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.88rem;
  }
}

.contacts__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8.125rem;
}
.contacts__content {
  padding-bottom: 8.125rem;
}
.contact-text {
  font-size: 1.125rem;
  margin-bottom: 20px;
}

.contacts-image img {
  width: 26.25rem;
  height: 26.25rem;
  border-radius: 50%;
}
.contacts__bottom {
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  padding-bottom: 5.625rem;
}
.contacts__bottom--left p {
  color: rgb(30, 30, 30);
  font-family: var(--font-family);
  font-size: 1.88rem;
  font-weight: 500;
  line-height: 3.13rem;
}
.contacts__socials {
  padding-top: 5.625rem;
}
.contacts__social {
  background: rgb(255, 255, 255) !important;
}
.contacts__social:hover {
  background: black !important;
}
.info-subtitle {
  font-family: var(--font-family);
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.75rem;
  color: rgb(0, 0, 0);
}
.contacts__map {
  background: url('./assets/image/contacts/map-image.jpeg') no-repeat center;
  width: 100%;
  height: 53.75rem;
  background-size: cover;
  border-radius: 3.75rem 3.75rem 0rem 0rem;
  overflow: hidden;
}

@media (max-width: 100rem) {
  .contacts__container {
    width: 90%;
  }
  .contacts-image img {
    width: 13.125rem;
    height: 13.125rem;
    border-radius: 50%;
  }
}
@media (max-width: 75rem) {
  .contacts__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0rem;
  }
  .contacts__bottom--left p {
    color: rgb(30, 30, 30);
    font-family: var(--font-family);
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.5rem;
  }
  .contacts-text {
    max-width: 34.375rem;
    margin-bottom: 0;
    padding-top: 1.875rem;
  }
  .contacts__content {
    padding-bottom: 5rem;
  }
  .info-subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.5rem;
  }
}
@media (max-width: 62rem) {
  .contacts-image img {
    display: none;
  }
}
@media (max-width: 48rem) {
  .contacts__bottom {
    display: flex;
    flex-direction: column;
    padding-bottom: 22.5rem;
    position: relative;
  }
  .contacts__socials {
    position: absolute;
    top: 23.75rem;
    padding-top: 5.625rem;
  }
  .contacts__bottom--left p {
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 30rem) {
  /* Ваші стилі тут */
}
@media (max-width: 22.5rem) {
  /* Ваші стилі тут */
}
