.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  font-weight: 600;
  font-family: var(--font-family);
  z-index: 1111;
  display: flex;
  align-items: center;
}

.nav__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 40px;
}

.logo {
  display: flex;
  align-items: center;
  width: 14.375rem;
  height: 3.125rem;
  z-index: 101;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.nav__item {
  position: relative;
}

.nav__link {
  display: inline-flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: 16px;
  text-align: center;
  color: var(--color-text);
  padding: 15.5px 20px;
  border-radius: 30px;
  background: white;
  transition: all 400ms;
  z-index: 101;
}

.menu-contacts {
  z-index: 101;
}

.nav__active {
  background: #f0f0f0;
  color: #000;
}

.nav__active::before {
  content: '';
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  transform: translateY(10%);
  margin-right: 3.25px;
  margin-left: 3px;
}

.nav__item:hover .nav__link,
.nav__item .dropdown-menu:hover .nav__link {
  color: var(--color-purpure);
}

.nav__item:first-child:hover .dropdown-menu {
  transition-delay: 0.2s;
  visibility: visible !important;
  opacity: 1;
}

.nav__right {
  display: flex;
  align-items: center;
}

.menu-toggle-btn {
  display: none;
  color: rgb(124, 107, 254);
  font-family: Urbanist;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0%;
  z-index: 101;
}

@media (max-width: 100rem) {
  .menu {
    display: none;
  }
  .menu-toggle-btn {
    display: block;
  }
}

@media (max-width: 75rem) {
}

@media (max-width: 62rem) {
  .logo {
    width: 11.56rem;
    height: 3.125rem;
    clip-path: inset(0 40% 0 0);
  }

  .logo img {
    width: 100%;
    height: auto;
    max-height: 100%;
  }
}

@media (max-width: 48rem) {
  .nav__wrapper {
    padding: 0 0.938rem;
  }
}

@media (max-width: 35.5rem) {
  .menu-contacts {
    display: none;
  }
  .right-menu {
    display: none !important;
  }
}
