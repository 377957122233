.hero {
  background: url('../../assets/image/bg-image.webp') no-repeat center;
  background-size: cover;
  width: 100%;
  height: 75rem;
  position: relative;
  overflow: hidden;
}

.hero__title {
  font-size: clamp(3.75rem, 2.8846rem + 3.8462vw, 7.5rem);
  max-width: 42rem;
  font-weight: 800;
  color: rgb(124, 107, 254);
  transition: all 0.5s ease;
  padding-top: 12.75rem;
}

.gradient-text {
  background: linear-gradient(90deg, #6c63ff, #b6b8f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero__text {
  font-weight: 800;
  font-size: 1.25rem;
  color: #fff;
  padding-top: 5.625rem;
}

.hero-swiper {
  position: absolute;
  bottom: 17.25rem;
  right: 25%;
}

.social-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2.5rem;
  position: absolute;
  bottom: 8.75rem;
}

.social-row__right button {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background: var(--color-purpure);
  cursor: pointer;
}

.social-row__right button img {
  width: 1.875rem;
  height: 1.875rem;
}

@media (max-width: 100rem) {
  .hero__container {
    width: 90%;
  }
}

@media (max-width: 75rem) {
  .hero__title {
    max-width: 35rem;
  }
  .hero-swiper {
    transform: scale(0.8);
  }
}

@media (max-width: 48rem) {
  .hero__title {
    max-width: 25rem;
  }
  .slider-container {
    transform: scale(0.69);
  }
  .hero-swiper {
    bottom: 19.5rem;
  }
  .social-row {
    padding: 0 0.938rem;
    position: absolute;
    bottom: 6.25rem;
  }
}
