.products-cart__video {
  width: 39.38rem;
  height: 40rem;
  border-radius: 2.5rem;
  background: rgb(209, 218, 221);
  overflow: hidden;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
}
@media (max-width: 100rem) {
  .products-cart__video {
    width: 19.38rem;
    height: 20rem;
    top: 50%;
    transform: translateY(-50%);
    right: 0.625rem;
  }
}

@media (max-width: 75rem) {
  .products-cart__video {
    display: block;
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
@media (max-width: 48rem) {
  .products-cart__video {
    top: 0%;
    left: 0%;
    margin-left: -0.625rem;
    transform: translate(0%, 0%);
  }
}
