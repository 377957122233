.starry-background {
  position: relative;
}
.starry-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-image: url('../../../assets/image/dome-models/stars.png');
  background-repeat: repeat;
  z-index: 1;
}
@media (max-width: 75rem) {
  .starry-background::before {
    animation: moveStars 30s linear infinite, fadeInOut 10s ease-in-out infinite;
  }
  @keyframes moveStars {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(100px, 100px);
    }
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.2;
  }
  20% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.content {
  position: relative;
}
.card {
  position: relative;
}
