.dome {
  width: 100%;
  border-radius: 3.75rem;
  background: rgb(36, 36, 36);
  margin-top: -3.75rem;
  padding-bottom: 7.5rem;
  position: relative;
  overflow-x: hidden;
}

.dome__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 6.875rem);
  grid-auto-rows: 8.75rem;
  gap: 1.25rem;
  position: relative;
}

.card:nth-child(1) {
  margin-left: -1.25rem;
  grid-column: 2 / span 3;
  grid-row: 2 / span 4;
  align-self: start;
  flex-direction: column-reverse;
}

.card:nth-child(2) {
  grid-column: 9 / span 3;
  grid-row: 3 / span 4;
  align-self: start;
  margin-top: -2.25rem;
  margin-left: 1.125rem;
  flex-direction: column-reverse;
}

.card:nth-child(1) .card__content .card__title,
.card:nth-child(2) .card__content .card__title {
  margin-top: 0rem;
}

.card:nth-child(3),
.card__content .card__title {
  margin-top: 1.875rem;
}

.card:nth-child(3) {
  grid-column: 1 / span 3;
  grid-row: 7 / span 4;
  align-self: start;
  margin-top: -1.875rem;
}

.card:nth-child(4) {
  grid-column: 7 / span 3;
  grid-row: 8 / span 4;
  margin-left: -0.625rem;
  align-self: start;
  margin-top: -3.125rem;
}

.dome__img--smal {
  width: 5.63rem;
  height: 5.63rem;
  border-radius: 1.25rem;
}

.smal-img-left {
  margin-top: 0.625rem;
  margin-left: -5.625rem;
  grid-column: 1 / span 1;
  grid-row: 4 / span 2;
}

.smal-img-top {
  margin-top: -2.5rem;
  margin-left: -1.875rem;
  grid-column: 9 / span 1;
  grid-row: 2 / span 1;
}

.smal-img-right {
  margin-left: 3.75rem;
  grid-column: 9 / span 1;
  grid-row: 11 / span 1;
}

.dome__content {
  grid-column: 3 / span 6;
  grid-row: 5 / span 4;
  min-width: 40rem;
  margin-left: 4.375rem;
  margin-top: 6.25rem;
  text-align: center;
  color: rgb(255, 255, 255);
}

.dome__title {
  margin-bottom: 1.875rem;
  font-family: var(--font-family);
  font-size: clamp(3.13rem, 2.6985rem + 1.9179vw, 5rem);
  font-weight: 700;
}

.un-dome__title {
  font-size: clamp(1.25rem, 1.1046rem + 0.6462vw, 1.88rem);
  font-weight: 500;
  line-height: clamp(1.88rem, 1.7369rem + 0.6359vw, 2.5rem);
}

.dome__img--middle {
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 2.5rem;
}

.middle-top {
  grid-row: 3 / span 2;
  grid-column: 6 / span 2;
  margin-left: 0.25rem;
  margin-top: 4.375rem;
}

.middle-center {
  grid-row: 7 / span 2;
  grid-column: 4 / span 2;
  margin-top: 5rem;
  margin-left: 3.125rem;
}

.middle-right {
  width: 11.25rem;
  height: 11.25rem;
  grid-row: 8 / span 2;
  grid-column: 10 / span 2;
  margin-left: 1.875rem;
}

.middle-bottom {
  grid-row: 11 / span 2;
  grid-column: 2 / span 5;
  margin-left: 5rem;
  margin-bottom: 7.5rem;
}

@media (max-width: 100rem) {
  .dome__container {
    width: 90%;
  }
  .dome__img--middle,
  .dome__img--smal {
    display: none;
  }

  .card:nth-child(1) {
    margin-left: 0;
    grid-column: 3 / span 3;
    grid-row: 2 / span 4;
  }

  .card:nth-child(2) {
    grid-column: 9 / span 3;
    grid-row: 2 / span 4;
    align-self: start;
    margin-top: 0;
    margin-left: 0;
  }

  .card:nth-child(3) {
    grid-column: 3 / span 3;
    grid-row: 8 / span 4;
    margin-top: 0;
  }

  .card:nth-child(4) {
    grid-column: 9 / span 3;
    grid-row: 8 / span 4;
    margin-left: 0rem;
    margin-top: 0;
  }

  .dome__content {
    margin-left: 30%;
    margin-top: 10rem;
  }
}

@media (max-width: 75rem) {
  .card:nth-child(1) {
    margin-left: 0;
    grid-column: 3 / span 3;
    grid-row: 2 / span 4;
  }

  .card:nth-child(2) {
    grid-column: 7 / span 3;
    grid-row: 2 / span 4;
    align-self: start;
    margin-top: 0;
    margin-left: 0;
  }

  .card:nth-child(3) {
    grid-column: 3 / span 3;
    grid-row: 8 / span 4;
    margin-top: 0;
  }

  .card:nth-child(4) {
    grid-column: 7 / span 3;
    grid-row: 8 / span 4;
    margin-left: 0rem;
    margin-top: 0;
  }

  .dome__content {
    margin-left: 20%;
    margin-top: 10rem;
  }
}

@media (max-width: 62rem) {
  .card:nth-child(1) {
    margin-left: 0;
    grid-column: 3 / span 3;
    grid-row: 2 / span 4;
  }

  .card:nth-child(2) {
    grid-column: 6 / span 3;
    grid-row: 2 / span 4;
    align-self: start;
    margin-top: 0;
    margin-left: 0;
  }

  .card:nth-child(3) {
    grid-column: 3 / span 3;
    grid-row: 8 / span 4;
    margin-top: 0;
  }

  .card:nth-child(4) {
    grid-column: 6 / span 3;
    grid-row: 8 / span 4;
    margin-left: 0rem;
    margin-top: 0;
  }

  .dome__content {
    margin-left: 0;
    margin-top: 10rem;
  }
}

@media (max-width: 48rem) {
  .dome__container {
    width: 90%;
    position: relative;
  }
  .dome {
    padding-bottom: 17.5rem;
  }

  .dome__wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 3.75rem;
  }

  .dome__content {
    margin-left: 50%;
    transform: translate(-50%);
    margin-top: 5.625rem;
    margin-bottom: 0rem;
  }
  .dome__title {
    margin-bottom: 0.625rem;
  }
  .un-dome__title {
    max-width: 335px;
    margin: 0 auto;
  }
  .card:nth-child(4),
  .card:nth-child(3) {
    flex-direction: column-reverse;
  }
  .card {
    transform: translateX(50%);
  }
}

@media (max-width: 40rem) {
  .card {
    transform: translateX(40%);
  }
}

@media (max-width: 36.5rem) {
  .card {
    transform: translateX(28%);
  }
}

@media (max-width: 31rem) {
  .card {
    transform: translateX(0%);
  }
}

@media (max-width: 30rem) {
  .dome__container {
    width: 97%;
  }
}
