footer {
  width: 100%;
  border-radius: 60px 60px 0px 0px;
  background: rgb(255, 255, 255);
  margin-top: -5.875rem;
  position: relative;
  overflow: hidden;
  z-index: 10;
}
.footer__top {
  padding-top: 10rem;
  margin-bottom: 6.25rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.footer__list {
  display: flex;
  margin-bottom: 1.875rem;
}
.btn-touch {
  display: inline-flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: 1.25rem;
  height: 80px;
  font-weight: 600;
  padding: 1.875rem 2.344rem 1.875rem 1.875rem;
  border: 0.06rem solid rgb(124, 107, 254);
  border-radius: 2.5rem;
  position: absolute;
  bottom: 1.875rem;
  left: 21.875rem;
  transition: all 400ms ease;
  cursor: pointer;
}
.btn-touch__icon {
  margin-left: 1.1rem;
  transition: fill 0.3s ease;
}
.btn-touch__icon .path {
  fill: black;
}
.btn-touch:hover .btn-touch__icon .path {
  fill: var(--color-purpure);
}
.btn-touch:hover {
  color: var(--color-purpure);
}
.footer__social {
  display: inline-flex;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 0.06rem solid rgb(209, 218, 221);
  background: rgb(255, 255, 255);
  position: relative;
}

.footer__link img {
  width: 1.875rem;
  height: 1.875rem;
  position: absolute;
  top: 30%;
  left: 30%;
}
.footer__title {
  max-width: 53.75rem;
  color: rgb(36, 36, 36);
  font-family: var(--font-family);
  font-size: clamp(5.63rem, 5.3427rem + 1.2769vw, 6.875rem);
  font-weight: 600;
  line-height: clamp(6rem, 5.3654rem + 2.8205vw, 8.75rem);
}
.footer__socials {
  display: flex;
  align-items: end;
}
.footer__wrapper {
  display: flex;
  gap: 15rem;
  position: relative;
}
.footer__social {
  cursor: pointer;
  transition: all 600ms ease;
}

.our-products__item {
  margin-bottom: 0.75rem;
}
.our-products__item p {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: #000;
}
.our-products__link {
  font-family: var(--font-family);
  font-size: 0.88rem;
  font-weight: 500;
  color: #000;
  transition: all 300ms ease;
}
.our-products__link:hover {
  color: var(--color-purpure);
}
.contscts-us__link {
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-weight: 500;
}

.back-to-top {
  position: absolute;
  bottom: 0.313rem;
  right: 0;
  font-family: var(--font-family);
  font-size: 0.875rem;
  font-weight: 700;
  color: #000;
  background: white;
  cursor: pointer;
  transition: all 400ms ease;
}
.back-to-top:hover {
  color: rgb(124, 107, 254);
}
.back-to-top::after {
  content: '';
  background: url(../../assets/image/reviews-section/arrow.png);
  background-size: cover;
  transform: rotate(-45deg);
  width: 0.79rem;
  height: 0.79rem;

  display: inline-block;
  margin-left: 0.7rem;
}
.footer__bottom {
  padding-top: 14rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.125rem;
}
.banner__top {
  position: absolute;
  top: 3.75rem;
}

.banner__email {
  position: absolute;
  bottom: 6.25rem;
  right: 0;
}
@media (max-width: 100rem) {
  footer {
    background: rgb(209, 218, 221);
  }
  .footer__top {
    flex-direction: column;
    justify-content: center;
  }

  .back-to-top {
    background: rgb(209, 218, 221);
  }
  .footer__bottom {
    justify-content: start;
    margin-bottom: 4.125rem;
    gap: 1.25rem;
  }

  .footer__wrapper {
    display: flex;
    justify-content: start;
    gap: 5rem;
  }
  .footer__container {
    width: 97%;
  }
}

@media (max-width: 64rem) {
  .footer__top {
    padding-top: 10rem;
    margin-bottom: 6.25rem;
    display: block;
  }
  .banner-education svg {
    height: 5rem;
  }

  .footer__bottom {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
}
@media (max-width: 62rem) {
  .footer__wrapper {
    display: flex;
    justify-content: center;
    gap: 2.813rem;
  }

  .banner__email {
    bottom: 6rem;
  }
  .back-to-top {
    bottom: -9rem;
    right: 50%;
    transform: translateX(50%);
  }
  .footer__title {
    margin-bottom: 7.5rem;
  }
  .btn-touch {
    align-items: center;
    top: 25rem;
  }
  .footer__list {
    margin-bottom: 0;
  }
  .footer__bottom {
    padding-top: 10rem;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
}

@media (max-width: 48rem) {
  .footer__wrapper {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
  }
  .footer__title {
    max-width: 28.125rem;
    margin-bottom: 7.5rem;
  }

  .footer__top {
    padding-top: 10rem;
    margin-bottom: 3.75rem;
    display: block;
  }
  .banner-education svg {
    height: 3.75rem;
  }

  .banner__email {
    bottom: 9rem;
  }
  .back-to-top {
    position: absolute;
    bottom: -8.125rem;
    right: 50%;
    transform: translateX(50%);
  }
  .btn-touch {
    align-items: center;
    top: 30rem;
    left: 0rem;
  }
}
@media (max-width: 30rem) {
  .btn-touch {
    align-items: center;
    top: 29rem;
    left: 0rem;
  }
  .banner__email {
    bottom: 11rem;
  }
}
