.products-cart {
  width: 100%;
  min-height: 41.25rem;
  border-radius: 2.5rem;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1.875rem;
}
.products-cart:last-child {
  margin-bottom: 0rem;
}
.products-cart__content {
  padding-top: 3.75rem;
  padding-left: 3.75rem;
  padding-bottom: 3.75rem;
}
.products-cart__title {
  color: rgb(0, 56, 255);
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-weight: 700;
}
.products-cart__subtitle {
  font-weight: 600 !important;
  padding-bottom: 1.875rem;
  max-width: 29.38rem;
}
.products-cart__description {
  padding-bottom: 3.125rem;
  max-width: 29.38rem;
}
.ds__text-small {
  color: rgb(30, 30, 30);
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.88rem;
}
.products-cart__btn {
  padding: 1.688rem 2.48rem;
  border-radius: 2.5rem;
  background: rgb(124, 107, 254);
  color: white;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
  transition: all 400ms ease;
  cursor: pointer;
  position: absolute;
  bottom: 3.75rem;
  left: 3.75rem;
}
.products-cart__btn:hover {
  border: 1px solid rgb(124, 107, 254);
  color: rgb(124, 107, 254);
  background: white;
}
.products-cart__image {
  width: 39.38rem;
  height: 40rem;
  border-radius: 2.5rem;
  background: rgb(209, 218, 221);
  overflow: hidden;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
}
.products-cart__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 100rem) {
  .products-cart {
    width: 85%;
  }
  .products-cart__subtitle {
    max-width: 24rem;
  }
  .products-cart__description {
    max-width: 24rem !important;
  }
  .products-cart__image {
    width: 19.38rem;
    height: 20rem;
    top: 50%;
    transform: translateY(-50%);
    right: 0.625rem;
  }
}

@media (max-width: 75rem) {
  .products-cart {
    width: 35rem;
  }

  .products-cart__image {
    display: block;
    position: relative;

    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .products-cart__content {
    padding-top: 1.875rem;
    padding-left: 1.25rem;
    padding-bottom: 1.875rem;
  }
  .products-cart__subtitle {
    padding-top: 1.875rem;
    padding-bottom: 2.5rem;
  }
  .ds__text-small {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.63rem;
  }
  .products-cart__description {
    padding-top: 1.875rem;
    padding-bottom: 8.125rem;
  }
  .products-cart__btn {
    bottom: 1.875rem;
    left: 1.25rem;
  }
  .products-cart__content {
    padding-bottom: 0rem;
  }
  .products-cart__title {
    font-size: 0.88rem;
    font-weight: 700;
    line-height: 1.25rem;
  }
  .products-cart__subtitle {
    font-size: 1.88rem;
    font-weight: 600;
    line-height: 2.5rem;
  }
}

@media (max-width: 48rem) {
  .products-cart {
    width: 20.63rem;
  }
  .products-cart__description {
    max-width: 18.13rem;
  }
  .products-cart__image {
    top: 0%;
    left: 0%;
    margin-left: -0.625rem;

    transform: translate(0%, 0%);
  }
  .products-cart__content {
    padding-left: 1.25rem;
  }
}
