.card {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  width: 19.375rem;
  height: auto;
  z-index: 3;
}
.card__link {
  cursor: pointer;
  transition: all 400ms ease;
}
.card__image {
  border-radius: 2.5rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 400ms ease;
}
.card__link:hover .card__image {
  scale: 0.9;
}
.card__title {
  font-family: var(--font-family);
  font-size: 1.875rem;
  color: white;
  margin-bottom: 0.625rem;
}
.card__description {
  font-weight: 600;
  line-height: 1.25rem;
  color: white;
  margin-bottom: 1.875rem;
}
@media (max-width: 30rem) {
  .card {
    overflow: hidden;
    width: 20.63rem;
    height: auto;
  }
  .card__image {
    height: 18.75rem;
    object-fit: cover;
  }
}
