.custom-slider-overlay {
  position: fixed;
  bottom: -100px;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(8, 2, 11, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
}

.custom-slider-container {
  position: relative;

  width: 100%;
}

.custom-slider-images {
  display: flex;
  transition: transform 0.5s ease;
}

.slider-image-wrapper {
  width: 33.33%;
  height: 26.25rem;
}

.custom-slider-image {
  width: 33.13rem;
  height: 26.25rem;
  border-radius: 1.25rem;
  object-fit: cover;
}

.custom-slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgb(30, 30, 30);
  border: none;
  cursor: pointer;
  padding: 0.55rem 1.38rem;
  border-radius: 50%;
  font-size: 2rem;
  z-index: 10;
}
.custom-slider-button span {
  color: white;
}
.custom-prev {
  left: 3rem;
}

.custom-next {
  right: 3rem;
}

.custom-pagination {
  display: flex;
  justify-content: center;
  width: 9.38rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  background: rgb(255, 255, 255);
  margin-top: 3.75rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
}

.custom-pagination-dot {
  width: 0.63rem;
  height: 0.63rem;
  margin: 0 5px;
  border-radius: 50%;
  background-color: rgb(209, 218, 221);
  border: none;
  cursor: pointer;
}

.custom-pagination-dot.active {
  background-color: rgb(124, 107, 254);
}

.custom-close-slider {
  position: absolute;
  top: -25%;
  right: 20%;
}

@media (max-width: 790px) {
  .custom-slider-container {
    height: 30rem;
  }
  .custom-slider-image {
    width: 21.25rem;
  }
}

@media (max-width: 480px) {
  .custom-slider-container {
    height: 25rem;
  }
  .custom-slider-button {
    display: none;
  }
}
