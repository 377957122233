.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.slide-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0.13rem solid rgba(255, 255, 255, 0.3);
  border-radius: 5rem;
  padding: 0.25rem;
  margin-right: 1.563rem;
  opacity: 0.5;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: scale(0.8);
}
.slide-item.active {
  opacity: 1;
  transform: scale(1);
}
.slide-image {
  width: 15rem;
  height: 10rem;
  border-radius: 5rem;
  z-index: 3;
}
.slide-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: Urbanist;
  font-size: 1.88rem;
  font-weight: 600;
  z-index: 3;
}
.timer {
  width: 15.7rem;
  height: 10.7rem;
  border-radius: 5.31rem;
  position: absolute;
  top: -10px;
  left: -1px;
  transform: translateY(8px);
  overflow: hidden;
  z-index: 2;
}
.timer span {
  display: block;
  height: 100%;
  background-color: white;
  width: 100%;
  transition: none;
  position: absolute;
  right: 2px;
}
.active .timer span {
  animation: timerAnimation 6000ms linear infinite;
}
@keyframes timerAnimation {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
